import { useEffect, useState } from "react";
import { Card } from "../Card";
import { client } from "../../api";

const OrderId = ({ orderId }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(orderId)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);  // Reset the copied state after 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy order ID:', err);
      });
  };

  return (
    <div className={`table-cell text-left text-xs text-center rounded-md font-medium ${!copied ? 'text-blue-500 underline' : 'text-white bg-green-500'} cursor-pointer `} onClick={handleCopyClick}>
      {!copied ? orderId.slice(19) : 'copied!'}
    </div>
  );
};

export const OrderList = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const { data } = await client({
        method: 'GET',
        url: '/orders',
        headers: {
          "Content-Type": "application/json",
        },
      });

      setOrders(data || []);
    };

    fetchOrders();

  }, []);

  return <div className='flex flex-col space-y-5 w-full'>
    <Card
      title={'Orders'}
      prompt={false}
    >
      <div className='table w-full overflow-y-auto'>
        <div className='table-row space-y-2'>
          <div className='table-cell text-left text-xs font-bold pl-2 text-slate-gray border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Id
          </div>
          <div className='table-cell text-left text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Agent Name
          </div>
          <div className='table-cell text-right text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Amount Captured
          </div>
          <div className='table-cell text-right text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Amount Ordered
          </div>
          <div className='table-cell text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            States
          </div>
        </div>
        {orders?.reduce((acc, { orderId, agentId, leadCountRequested, leadCountCaptured, leadCountRemaining, state, agentName }) => {
          const existing = acc.find(({ orderId: _orderId }) => _orderId === orderId);

          if (existing) {
            existing.leadCountCaptured += leadCountCaptured;
            existing.states = existing?.states || [];
            existing.states.push(state);
          } else {
            acc.push({ orderId, agentId, leadCountRequested, leadCountCaptured, state, states: [state], leadCountRemaining, agentName });
          }

          return acc;
        }, [])
          .sort((a, b) => a.orderId - b.orderId)
          .map(({ orderId, leadCountRequested, leadCountCaptured, agentName, states }) => <div className='table-row pb-1'>
            <OrderId orderId={orderId} />
            <div className='table-cell text-left text-xs pl-2 text-slate-gray'>
              {agentName}
            </div>
            <div className='table-cell text-right text-xs pl-2 text-slate-gray'>
              {leadCountCaptured} lead{leadCountCaptured === 1 ? '' : 's'}
            </div>
            <div className='table-cell text-right text-xs pl-2 text-slate-gray'>
              {leadCountRequested} lead{leadCountRequested === 1 ? '' : 's'}
            </div>
            <div className='table-cell text-xs pl-2 text-slate-gray'>
              <div className="flex flex-row">
                <div className="overflow-x-auto w-32 whitespace-nowrap display-none"
                  style={{
                    // For Chrome, Safari and Opera
                    WebkitOverflowScrolling: 'touch',
                    '::-webkit-scrollbar': {
                      display: 'none'
                    },
                    // For Firefox
                    scrollbarWidth: 'none',
                    // For IE and Edge
                    msOverflowStyle: 'none'
                  }}
                >
                  {states?.join(', ')}
                </div>
                {states?.length > 6 && <div
                  className="text-right pl-1"// bg-gradient-to-r from-transparent to-white"
                >...</div>}
              </div>
            </div>
          </div>)}
      </div>
    </Card>
  </div>;

};