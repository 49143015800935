import { Screen } from './components/Screen';
import { Outlet, Route, Routes } from "react-router-dom";

import { CreateAgent } from './components/CreateAgent';
import { CreateOrder } from './components/CreateOrder';
import { OrderList } from './components/OrderList';

//Test

const EntryScreen = () => {
  return <Screen>
    {/* <div className='absolute top-0 left-0 w-full z-0 overflow-hidden h-2/5'>
      <img alt='banner' src={banner} className='absolute top-0 left-0 w-full h-full object-cover' />
      <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-neutral-100"></div>
    </div> */}
    <Outlet />
  </Screen>
};

const App = () => {
  return <Routes>
    <Route path='/' element={<EntryScreen />}>
      <Route index element={<div className='flex w-full justify-center items-center'>
        <div className='flex flex-col w-[600px] space-y-7 justify-center items-center'>
          <h1 className='text-3xl font-black text-slate-gray self-start'>Smart Form Admin</h1>
          <OrderList />
          <div className='flex space-x-7 w-full'>
            <CreateAgent />
            <CreateOrder />
          </div>
        </div>
      </div>} />
    </Route>
  </Routes>;
}

export default App;
