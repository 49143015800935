import { useForm } from 'react-hook-form';
import { Button } from '../Button';
import { Card } from '../Card';
import { client } from '../../api';
import { useState } from 'react';
import useFormattedNumber from '../../hooks/useFormattedNumber';

export const CreateAgent = () => {
  // const { state: { data } } = useLocation();
  const [loading, setLoading] = useState(false);
  const [formattedPhoneNumber, setFormattedNumber] = useFormattedNumber();

  const { handleSubmit, getValues, register, formState: { errors, touchedFields } } = useForm();

  const onSubmit = async (data) => {
    setLoading(true)
    await client({
      method: 'POST',
      url: '/agent',
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ...data,
        phoneNumber: data.phoneNumber.replace(/\D/g, ''),
      },
    });
    setLoading(false)
  };

  return <div className='flex flex-col space-y-5 w-full'>
    <Card
      title={'Create an Agent'}
      prompt={false}
    >
      <label className='w-full'>
        <span class="block text-xs font-medium text-slate-gray pb-1">
          First Name
        </span>
        <input name='firstName' id='firstName' type="text" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.firstName ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('firstName') || ''} {...register('firstName', { required: true })} />
        {touchedFields.firstName && errors.firstName && <p className='mt-1 text-xs text-red-400'>{errors.firstName.message}</p>}
      </label>
      <label className='w-full'>
        <span class="block text-xs font-medium text-slate-gray pb-1">
          Last Name
        </span>
        <input name='lastName' id='lastName' type="text" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.lastName ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('lastName') || ''} {...register('lastName', { required: true })} />
        {touchedFields.lastName && errors.lastName && <p className='mt-1 text-xs text-red-400'>{errors.lastName.message}</p>}
      </label>
      <label className='w-full'>
        <span class="block text-xs font-medium text-slate-gray pb-1">
          Phone
        </span>
        <input name='phoneNumber' id='phoneNumber' type="phoneNumber" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.phoneNumber ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('phoneNumber') || ''} {...register('phoneNumber', { required: true })}
          value={formattedPhoneNumber}
          onChange={e => {
            setFormattedNumber(e.target.value);
          }}
        />
        {touchedFields.phoneNumber && errors.phoneNumber && <p className='mt-1 text-xs text-red-400'>{errors.phoneNumber.message}</p>}
      </label>
      <label className='w-full'>
        <span class="block text-xs font-medium text-slate-gray pb-1">
          Google Sheet Id
        </span>
        <input name='googleSheetId' id='googleSheetId' type="text" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.googleSheetId ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('googleSheetId') || ''} {...register('googleSheetId', { required: true })} />
        {touchedFields.googleSheetId && errors.googleSheetId && <p className='mt-1 text-xs text-red-400'>{errors.googleSheetId.message}</p>}
      </label>
      <label className='w-full'>
        <span class="block text-xs font-medium text-slate-gray pb-1">
          Google Tab Name
        </span>
        <select defaultValue={getValues('googleSheetTabId') || ''} {...register('googleSheetTabId', { required: true })} className='p-4 justify-center align-center item-center text-center font-bold bg-neutral-200 w-full rounded-lg' placeholder='State'>
          <option value={'Statewide | 2023 Fresh FEX Leads'}>Statewide | 2023 Fresh FEX Leads</option>
          <option value={'Statewide | 2023 Fresh FEX Leads | DBGA'}>Statewide | 2023 Fresh FEX Leads | DBGA</option>
        </select>
      </label>
    </Card>
    <Button loading={loading} label={'Create Agent'} color={'primary'} className='mt-4' onClick={handleSubmit(onSubmit)} />
  </div>;
}