import { useForm } from 'react-hook-form';
import { Button } from '../Button';
import { Card } from '../Card';
import { useEffect, useState } from 'react';
import { client } from '../../api';
import { Info } from '../Info';

export const CreateOrder = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);

  const form = useForm();

  const { handleSubmit, getValues, register, formState: { errors, touchedFields } } = form;

  useEffect(() => {
    const fetchAgents = async () => {
      const { data } = await client({
        method: 'GET',
        url: '/agents',
        headers: {
          "Content-Type": "application/json",
        },
      });

      setAgents(data || []);
    };

    fetchAgents();

  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    await client({
      method: 'POST',
      url: '/order',
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
    setLoading(false);
  };


  return <div className='flex flex-col space-y-5 w-full'>
    <Card
      title={'Create an Order'}
      prompt={false}
    >
      <label className='w-full'>
        <span class="block text-xs font-medium text-slate-gray pb-1">
          Agent
        </span>
        <select defaultValue={getValues('agentId') || ''} {...register('agentId', { required: true })} className='p-4 justify-center align-center item-center text-center font-bold bg-neutral-200 w-full rounded-lg' placeholder='State'>
          {agents
          // sort by last name and then first name
          .sort((a, b) => {
            if (a.lastName < b.lastName) {
              return -1;
            }
            if (a.lastName > b.lastName) {
              return 1;
            }
            if (a.firstName < b.firstName) {
              return -1;
            }
            if (a.firstName > b.firstName) {
              return 1;
            }
            return 0;
          })
          .map(({ agentId, firstName, lastName }) => <option value={agentId}>{lastName}, {firstName}</option>)}
        </select>
      </label>
      <label className='w-full'>
        <span class="block text-xs font-medium text-slate-gray pb-1">
          Lead Amount
        </span>
        <input name='leadCountRequested' id='leadCountRequested' type="text" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.leadCountRequested ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('leadCountRequested') || ''} {...register('leadCountRequested', { required: true })} />
        {touchedFields.leadCountRequested && errors.leadCountRequested && <p className='mt-1 text-xs text-red-400'>{errors.leadCountRequested.message}</p>}
      </label>
      <label className='w-full'>
        <div class="flex block text-xs font-medium text-slate-gray pb-1 space-x-1">
          <div>States</div>
          <Info>
            Select multiple states by holding down the control key and clicking on the states you want to select.
          </Info>
        </div>
        <select multiple defaultValue={getValues('states') || ''} {...register('states', { required: true })} className='p-4 justify-center align-center item-center text-center font-bold bg-neutral-200 w-full rounded-lg h-[99px]' placeholder='State'>
          <option value="AK">Alaska</option>
          <option value="AL">Alabama</option>
          <option value="AR">Arkansas</option>
          <option value="AZ">Arizona</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DC">District of Columbia</option>
          <option value="DE">Delaware</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="IA">Iowa</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="MA">Massachusetts</option>
          <option value="MD">Maryland</option>
          <option value="ME">Maine</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MO">Missouri</option>
          <option value="MS">Mississippi</option>
          <option value="MT">Montana</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="NE">Nebraska</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NV">Nevada</option>
          <option value="NY">New York</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="PR">Puerto Rico</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VA">Virginia</option>
          <option value="VT">Vermont</option>
          <option value="WA">Washington</option>
          <option value="WI">Wisconsin</option>
          <option value="WV">West Virginia</option>
          <option value="WY">Wyoming</option>
        </select>
      </label>
    </Card>
    <Button loading={loading} disabled={!(agents?.length)} label={'Create Order'} color={'primary'} className='mt-4' onClick={handleSubmit(onSubmit)} />
  </div>;
}